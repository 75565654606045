import {
  ButtonCopy,
  Flex,
  Heading,
  Stack,
  Tag,
  TextLine,
} from '@gr4vy/poutine-react'
import { HTTPBody } from 'transactions/components/HTTPBody'
import {
  formatCode,
  getCodeLanguage,
  getColorByMethod,
  getColorByStatusCode,
} from 'transactions/helpers'
import { TransactionEvent } from 'transactions/services'
import styles from './styles.module.scss'

export const RequestEvent = ({ event }: { event: TransactionEvent }) => {
  const requestLanguage = getCodeLanguage(event.context['request'])
  const responseLanguage = getCodeLanguage(event.context['response'])

  return (
    <>
      {(event.context['url'] ||
        event.context['method'] ||
        event.context['request']) && (
        <Stack gap={8}>
          <Flex alignItems="flex-end" justifyContent="space-between">
            <Flex alignItems="center" gap={8}>
              <Heading as="h5" level={5}>
                API request
              </Heading>
              {event.context['success'] === false && (
                // payment-connector-external-transaction-request specific
                <Tag variant={'subtle'} color={'negative'}>
                  Failed
                </Tag>
              )}
            </Flex>
            {event.context['request'] && requestLanguage && (
              <ButtonCopy
                type="button"
                variant="tertiary"
                copy={formatCode(event.context['request'])}
                size="small"
                className={
                  event.context['url'] || event.context['method']
                    ? styles.requestCopy
                    : styles.responseCopy
                }
              >
                Copy
              </ButtonCopy>
            )}
          </Flex>
          {(event.context['url'] || event.context['method']) && (
            <Stack direction="row" gap={8}>
              {event.context['method'] && (
                <Tag
                  variant="subtle"
                  color={getColorByMethod(event.context['method'])}
                >
                  {event.context['method']}
                </Tag>
              )}
              {event.context['url'] && (
                <TextLine fontFamily="mono" variant="med3">
                  {event.context['url']}
                </TextLine>
              )}
            </Stack>
          )}
          <HTTPBody
            code={event.context['request']}
            language={requestLanguage}
          />
        </Stack>
      )}

      {event.context['responseStatusCode'] && event.context['response'] && (
        <Stack gap={8}>
          <Flex alignItems="flex-end" justifyContent="space-between">
            <Flex alignItems="flex-end" gap={8}>
              <Heading as="h5" level={5}>
                API response
              </Heading>
              <Tag
                variant={'subtle'}
                color={getColorByStatusCode(
                  String(event.context['responseStatusCode'])
                )}
              >
                {event.context['responseStatusCode']}
              </Tag>
              {event.context['errorCode'] && (
                <Tag variant={'subtle'} color="negative">
                  {event.context['errorCode']}
                </Tag>
              )}
            </Flex>
            {responseLanguage && (
              <ButtonCopy
                type="button"
                variant="tertiary"
                copy={formatCode(event.context['response'])}
                size="small"
                className={styles.responseCopy}
              >
                Copy
              </ButtonCopy>
            )}
          </Flex>
          <HTTPBody
            code={event.context['response']}
            language={responseLanguage}
          />
        </Stack>
      )}

      {event.context['responseStatusCode'] && !event.context['response'] && (
        <Stack gap={8}>
          <Flex alignItems="flex-end" justifyContent="space-between">
            <Flex alignItems="flex-end" gap={8}>
              <Heading as="h5" level={5}>
                API response
              </Heading>
              <Tag
                variant={'subtle'}
                color={getColorByStatusCode(
                  String(event.context['responseStatusCode'])
                )}
              >
                {event.context['responseStatusCode']}
              </Tag>
              {event.context['errorCode'] && (
                <Tag variant={'subtle'} color="negative">
                  {event.context['errorCode']}
                </Tag>
              )}
            </Flex>
          </Flex>
        </Stack>
      )}

      {event.context['success'] === false && event.context['errorCode'] && (
        // payment-connector-external-transaction-request specific
        <Stack gap={8}>
          <Flex alignItems="flex-end" justifyContent="space-between">
            <Flex alignItems="flex-end" gap={8}>
              <Heading as="h5" level={5}>
                API response
              </Heading>
              {event.context['errorCode'] && (
                <Tag variant={'subtle'} color="negative">
                  {event.context['errorCode']}
                </Tag>
              )}
            </Flex>
          </Flex>
        </Stack>
      )}
    </>
  )
}
